
function Filter({ filterValues, name, isChecked, setValue, triggerAdd, filters }) {

    const addFilter = (event) => {
        triggerAdd(event.target.value);
    };

    const updateValue = (keyToUpdate, newValue) => {
        const elementsIndex = filterValues.findIndex(element => element[keyToUpdate] !== undefined);
        let newArray = [...filterValues];
        if (elementsIndex !== -1) {
            newArray[elementsIndex] = { ...newArray[elementsIndex], [keyToUpdate]: newValue };
            setValue(newArray);
        }
    };

    const setFilterValue = (event) => {
        updateValue(isChecked, event.target.value);
    };

    return (
        <div className="filter_container">
            <label className="checkbox">{name}<input type="checkbox" name="filter_selected" value={isChecked} checked={filters.includes(isChecked) ? 'checked' : ''} onChange={addFilter} />
                <div className="checkmark"></div>
            </label>
            {filters.includes(isChecked) ? <input type="text" value={filterValues[0][isChecked]} placeholder={name} onChange={setFilterValue} /> : ''}
        </div>
    );

}

export default Filter;