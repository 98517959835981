import { Dna } from 'react-loader-spinner';
import { useState, useEffect } from 'react';
import Result from "./Result";
import axios from 'axios';
import Popup from "./popup";

const UploadData = () => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(0);
    const [headerValues, setHeaderValues] = useState([]);
    const [rowData, setRow] = useState([]);
    const [isOpened, setPopup] = useState([]);
    const [fieldsToAdd, setFields] = useState([]);
    const [newFields, setNewFields] = useState([]);
    const [crossVal, setCross] = useState('');
    const [tags, setTags] = useState([]);
    const [chosenTags, setChosen] = useState([]);
    const [fileSource, setFileSource] = useState('');


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        const sortedArray = fieldsToAdd.sort((a, b) => {
            const keyA = Object.keys(a)[0];
            const keyB = Object.keys(b)[0];
            return keyA - keyB;
        });
        setFields(sortedArray);
    }, [newFields][fieldsToAdd]);

    const filterArray = (value, array, setState) => {
        if (array.includes(value)) {
            setState(array.filter(item => item !== value));
        } else {
            setState(prevArray => [...prevArray, value]);
        }
    };


    const removedSaved = (index) => {
        removeFromArray(index, newFields, setNewFields);
        removeFromArray(index, fieldsToAdd, setFields);
    };


    const removeFromArray = (index, array, updateState) => {
        const newArray = array.filter(obj => Object.keys(obj)[0] !== index.toString());
        updateState(newArray);
    };

    const closePopup = (index) => {
        filterArray(index, isOpened, setPopup);
    };

    const saveArray = (index, value, array, updateState) => {

        const keyExists = array.some((obj) => obj.hasOwnProperty(index));
        filterArray(index, isOpened, setPopup);
        const newObject = {
            [index]: value,
        };
        updateState((prevArray) => {
            if (keyExists) {
                // If the key exists, update its value in each object
                const newArray = prevArray.map((obj) => {
                    if (obj.hasOwnProperty(index)) {
                        obj[index] = newObject[index];
                    }
                    return obj;
                });
                return newArray;
            } else {
                // If the key doesn't exist, add the new object to the array
                return [...prevArray, newObject];
            }
        });
    };

    const saveNewFields = (index, value) => {
        saveArray(index, value, newFields, setNewFields);
    };

    const saveFields = (index, value) => {
        saveArray(index, value, fieldsToAdd, setFields);
    };

    const getFirstData = () => {
        setLoading(true);
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split('\n');
            let rows = [];
            if (lines.length > 0) {
                const headers = lines[0].split(',');
                for (let i = 1; i <= 7 && i < lines.length; i++) {
                    const rowData = lines[i].split(',');
                    rows.push(rowData);
                }
                setHeaderValues(headers);
                setRow(rows);
                setLoading(false);
                getTags();
            }
        };

        fileReader.readAsText(file);

    };



    const getTags = async () => {
        const formData = new FormData();
        formData.append('apiKey', 'u6SIRcX1UQ');
        const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=getTags', formData);
        setTags(data.data.tags);
    }

    const handleUpload = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        setLoading(true);


        const ROWS_PER_CHUNK = 1000; // Number of rows per chunk
        let uploadedChunks = 0;
        const fileReader = new FileReader();

        fileReader.onload = async (event) => {
            let content = event.target.result.split("\n");
            content = content.slice(1);
            const totalRecords = content.length;
            const totalChunks = Math.ceil(totalRecords / ROWS_PER_CHUNK);

            // Send each chunk to the PHP backend
            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const start = chunkIndex * ROWS_PER_CHUNK;
                const end = Math.min(start + ROWS_PER_CHUNK, totalRecords);
                const chunk = content.slice(start, end).join("\n");

                const formData = new FormData();
                formData.append('csvFile', new Blob([chunk], { type: 'text/plain' }));
                formData.append('fieldsToAdd', JSON.stringify(fieldsToAdd));
                formData.append('filesource', fileSource);
                formData.append('tags', JSON.stringify(chosenTags));
                formData.append('cross', crossVal);
                formData.append('apiKey', 'u6SIRcX1UQ');

                if (chunkIndex === 0 && newFields.length > 0) {
                    formData.append('newFields', JSON.stringify(newFields));
                } else {
                    formData.append('newFields', JSON.stringify([]));
                }


                try {
                    // Send the chunk to the PHP backend
                    const response = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=uploadcsv', formData);
                    console.log(response.data.output);
                    uploadedChunks = uploadedChunks + ROWS_PER_CHUNK;
                    const percentage = Math.min((uploadedChunks / totalRecords) * 100, 100);
                    setUploaded(percentage.toFixed(2));
                } catch (error) {
                    console.error('Error uploading chunk:', error.message);
                    setLoading(false);
                    return;
                }
            }

            console.log('File upload successful');
            setLoading(false);

        };

        fileReader.readAsText(file, 'UTF-8');
    };

    return (
        <div className="uploadFiles">
            <div className="top_section">
                <div className="uploadBox">
                    <div className="uploadField">
                        <input type="file" id="fileInput" className="custom-file-input" onChange={handleFileChange} />
                        <label htmlFor="fileInput" className="custom-file-label">בחר קובץ</label>
                        <span className="filename">{file ? file.name : ''}</span>
                        <button onClick={getFirstData}>העלאת קובץ</button>
                    </div>
                    {loading ? (
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    ) : ''}
                    <div className="uploadedBox">
                        <div className="uploadedAmount" style={{ "width": `${uploaded}%` }}>{uploaded}%</div>
                    </div>
                </div>
                {headerValues.length > 0 ?
                    <div className="cross">
                        <select name="cross" onChange={(e) => setCross(e.target.value)} value={crossVal}>
                            <option value="none">ללא הצלבה</option>
                            <option value="phone">הצלבה לפי טלפון</option>
                            <option value="idnumber">הצלבה לפי ת"ז</option>
                        </select>
                    </div>
                    : ''}
            </div>
            {headerValues.length > 0 ?
                <div className="result-container firstData">
                    <div className="result-line header-line">
                        {headerValues.map((value, index) => (
                            <div className="column" key={index}>
                                <input type="checkbox" name="field" value={index} onClick={(e) => !e.target.checked ? removedSaved(index) : filterArray(index, isOpened, setPopup)} />
                                {value}
                                {isOpened.includes(index) ? <Popup id={index} saveFields={saveFields} saveNew={saveNewFields} closePopup={closePopup} /> : ''}
                            </div>
                        ))}
                    </div>
                    <div className="seperator"></div>
                    <Result rowData={rowData} />
                </div> : ''}
            {headerValues.length > 0 ? <div className="bottom">
                <div className="bottomFields">
                    <input type="text" name="filesource" placeholder="שם הקובץ" onChange={(e) => setFileSource(e.target.value)} value={fileSource} />
                    <div className="tags_container">
                        <span>בחר תגיות</span>
                        <div className="tags_choose">
                            {tags.map((tag, index) => {
                                return <div className="tag" key={index}><span>{tag.tag}</span><input type="checkbox" value={tag.tag} onClick={(e) => filterArray(e.target.value, chosenTags, setChosen)} /></div>
                            })}
                        </div>
                    </div>
                </div>
                <button className="approveUpload" onClick={handleUpload}>אשר העלאת קובץ</button>
            </div> : ''}
        </div>
    );

};

export default UploadData;
