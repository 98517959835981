function Result({ rowData }) {
    return (
        rowData.map((data, index) => (
            <div className="result-line data-fill-line" key={index}>
                <div className="column">
                    {data}
                </div>
            </div>
        ))
    );
}

export default Result;