import logo from "./images/logo.png";
function Header() {
    return (
        <div className="header">
            <div className="container">
                <div className="logo"><img src={logo} alt="logo" /></div>
            </div>
        </div>
    );
}

export default Header;