import axios from "axios";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Dna } from 'react-loader-spinner';

function Login({ lastProp }) {

    const [emailCode, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [inputCode, setInput] = useState('');
    const [error, setError] = useState('');
    const [cookies, setCookie] = useCookies(['username']);


    const startLogin = async () => {
        setLoading(true);
        setError('');
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('apiKey', 'u6SIRcX1UQ');
        const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=login', formData);
        if (data.data.code) {
            setCode(data.data.code);
        } else {
            setError(data.data.error);
        }
        setLoading(false);
    };

    const lastLogged = async () => {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('apiKey', 'u6SIRcX1UQ');
        await axios.post('https://adi.otzma.co/api/dataFetching.php?request=lastLogged', formData);
    };

    const setCookieFunc = () => {
        setCookie('username', username, { path: '/' });
        lastLogged();
    };

    const doLogin = () => {
        if (inputCode !== emailCode) {
            setError('הקוד שהכנסת שגוי..');
        } else {
            setCookieFunc();
        }
    };

    return (
        <div className="login-page">
            <h1>התחברות למערכת</h1>
            <form method="post">
                <div className="login-form">
                    {!loading ? (!emailCode ? (
                        <>
                            <input type="text" name="username" autoComplete="true" value={username} placeholder="שם משתמש" onChange={(e) => setUsername(e.target.value)} />
                            <input type="password" name="password" value={password} placeholder="סיסמא" onChange={(e) => setPassword(e.target.value)} />
                            <button onClick={startLogin}>התחברות</button>
                        </>
                    ) : (
                        <div className="email-code">
                            <input type="email" name="email" placeholder="קוד שהתקבל בדואר אלקטרוני" onChange={(e) => setInput(e.target.value)} />
                            <button onClick={doLogin}>התחבר</button>
                        </div>
                    )) : <Dna
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="dna-loading"
                        wrapperStyle={{}}
                        wrapperClass="dna-wrapper"
                    />}
                    <div className="error">{error}</div>
                </div>
            </form>
        </div>
    );
}

export default Login;
