import { useEffect, useState } from "react";
import axios from "axios";
import Result from "./Result";
import { Dna } from 'react-loader-spinner';

function Tags() {
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');
    const [newAddedTag, setAddTag] = useState('');
    const [currentTag, setCurrent] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpened, setIsOpened] = useState([]);
    const [sumRecords, setSum] = useState(0);

    useEffect(() => {
        if (tags.length < 1) {
            getTags();
        } if (sumRecords) {
            updateTag();
        }
    }, [sumRecords]);

    const getTags = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('apiKey', 'u6SIRcX1UQ');
        try {
            const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=getTags', formData);
            setTags(data.data.tags);
        } catch (error) {
            console.error('Error recieving data:', error.message);
            setLoading(false);
            return;
        }
        setLoading(false);
    }


    const getSumRecords = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('apiKey', 'u6SIRcX1UQ');
        formData.append('tags', currentTag);
        try {
            const sumRecords = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=getSumRecords', formData);
            setSum(sumRecords.data);
        } catch (error) {
            console.error('Error uploading chunk:', error.message);
            setLoading(false);
            return;
        }
        setLoading(false);

    }


    const updateTag = async () => {
        setLoading(true);
        const chunks = 1000;
        const totalChunks = Math.ceil(sumRecords / chunks);
        for (let i = 0; i < totalChunks; i++) {
            const offset = i * chunks;
            const formData = new FormData();
            formData.append('apiKey', 'u6SIRcX1UQ');
            formData.append('tag_value', currentTag);
            formData.append('new_tag', newTag);
            formData.append('offset', offset);
            const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=updateTag', formData);
            console.log(data.data);
            console.log(offset);
            console.log("Chunks left to process: " + (totalChunks - i - 1));
        }
        setLoading(false);
    }

    const addTag = async () => {

        setLoading(true);
        const formData = new FormData();
        formData.append('apiKey', 'u6SIRcX1UQ');
        formData.append('value', newTag);

        try {
            const data = await axios.post('https://adi.otzma.co/api/dataFetching.php?request=newTag', formData);
            if (data.data.result) {
                getTags();
                setAddTag('');
            }
        } catch (error) {
            console.error('Error adding new tag:', error.message);
            setLoading(false);
            return;
        }
        setLoading(false);

    };


    const isopened = (itemID) => {
        if (isOpened.includes(itemID)) {
            setIsOpened(isOpened.filter(item => item !== itemID));
        } else {
            setIsOpened(prevArray => [...prevArray, itemID]);
        }
    };

    return (
        <div>
            <input type="text" name="tag" placeholder="הכנס שם תגית" value={newAddedTag} onChange={(e) => setAddTag(e.target.value)} />
            <button className="addTag" onClick={addTag}>הוספת תגית חדשה</button>
            <div className="results tags">
                <div className="result-top">
                    <div className="column">שם התגית</div>
                    <div className="column">עוד מידע</div>
                </div>
                {tags.length < 1 || loading ? (
                    <div className="loading">
                        <Dna
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        />
                    </div>
                ) : (
                    tags.map(item => (
                        <Result
                            item={item}
                            isOpened={isopened}
                            openedStatus={isOpened}
                            key={item.id}
                            currentTag={setCurrent}
                            newTag={setNewTag}
                            updateTag={getSumRecords}
                        />
                    ))
                )}
            </div>
        </div>
    )

}

export default Tags;