function ResultFirst({ rowData, keys }) {

    return (
        rowData.map((data, index) => (
            <div className="result-line" key={index}>
                {keys.map((key, colIndex) => (
                    <div key={colIndex} className="column">
                        {data[key]}
                    </div>
                ))}
            </div>
        ))
    );

}

export default ResultFirst;