import React from 'react';
import down from "../../images/down.svg";
import opened from "../../images/up.svg";
import { motion, AnimatePresence } from 'framer-motion';

// Result component
function Result({ item, isOpened, openedStatus }) {
    const tagsDisolve = (tags) => {
        return tags.split(",").map(tag => {
            return (
                <div className="tagContainer" key={tag}>{tag}</div>
            );
        });
    };

    const isStatus = (className, secondClass) => {
        return (openedStatus.includes(item.id) ? className : (secondClass ? secondClass : ''));
    };

    return (
        <div className="result-container">
            <div className={`result-line ${isStatus('active-result')}`}>
                <div className="column">{item.firstname}</div>
                <div className="column">{item.lastname}</div>
                <div className="column">{item.phone}</div>
                <div className="column">{item.filesource}</div>
                <div className="column action-column">
                    <div className={isStatus('opened', 'closed')} onClick={() => isOpened(item.id)}>
                        {openedStatus.includes(item.id) ? <img src={opened} alt="opened-icon" /> : <img src={down} alt="closed-icon" />}
                    </div>
                </div>
            </div>
            <AnimatePresence>
                {openedStatus.includes(item.id) && (
                    <motion.div
                        key="details"
                        className={`details_block ${isStatus('show_details')}`}
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                    >
                        <div className="result-top">
                            <div className="column">ת"ז</div>
                            <div className="column">מין</div>
                            <div className="column">עיר</div>
                            <div className="column">רחוב</div>
                            <div className="column">שם האב</div>
                            <div className="column">תגיות</div>
                        </div>
                        <div className="more_details result-line">
                            <div className="column">{item.idnumber}</div>
                            <div className="column">{item.gender}</div>
                            <div className="column">{item.city}</div>
                            <div className="column">{item.street} - דירה:{item.apartment}</div>
                            <div className="column">{item.fathername}</div>
                            <div className="column">{tagsDisolve(item.tags)}</div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

export default Result;